import "./App.css";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Components/Layout";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectCurrentToken } from "./Features/Auth/AuthSlice";
import { Suspense } from "react";
import { getDecryptedData } from './Services/commons'
import CustomLoader from "./Components/Loader/CustomLoader";
import NetworkConnectionHandler from "./Components/NetworkConnectionHandler/NetworkConnectionHandler"
import { QueryClient, QueryClientProvider } from 'react-query';

const Header = React.lazy(() => import("./Components/Header/Header"));
const Dashboard = React.lazy(() => import("./Components/Dashboard/Dashboard"));
const SuccessEmailVerificationPage = React.lazy(() => import("./Pages/SuccessEmailVerificationPage"));
const DataEnrichmentPage = React.lazy(() => import("./Pages/DataEnrichmentPage"));
const DataEnrichmentDemoPage = React.lazy(() => import("./Pages/DataEnrichmentDemoPage"));
const SdkKeysPage = React.lazy(() => import("./Pages/SdkKeysPage"));
const ResetPasswordPage = React.lazy(() => import("./Pages/ResetPasswordPage"));
const CompanyProfilePage = React.lazy(() => import("./Pages/CompanyProfilePage"));
const AuthPage = React.lazy(() => import("./Pages/AuthPage"));
const UpdatePasswordPage = React.lazy(() => import("./Pages/UpdatePasswordPage"));
const OrganizationProfilePage = React.lazy(() => import("./Pages/OrganizationProfilePage"));
const SuccessfulInvitationConfirmationPage = React.lazy(() => import("./Pages/SuccessfulInvitationConfirmationPage"));
const EmailVerificationPage = React.lazy(() => import("./Pages/EmailVerification"));
const OnboardingContainer = React.lazy(() => import("./Components/CompanyOnboarding/OnboardingContainer"));
const DocumentationPage = React.lazy(() => import("./Pages/DocumentationPage"));



const App = () => {
  const queryClient = new QueryClient();
  let currentUser = useSelector(selectCurrentUser);
  let token = useSelector(selectCurrentToken);
  const userLocalStorage = getDecryptedData("user");
  const tokenLocalStorage = getDecryptedData("token");
  if (typeof userLocalStorage !== "undefined") {
    try {
      currentUser = JSON.parse(userLocalStorage);
      token = tokenLocalStorage;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
    <div className="max-w-full">
      <ToastContainer
        position="top-right"
        autoClose={500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <NetworkConnectionHandler>
        <Suspense fallback={<div style={loadingText}>loading ...</div>}>
          {currentUser && currentUser.password_reset === true ? <Header /> : null}
          <Suspense fallback={<div style={overlayDiv}>
            <CustomLoader />
          </div>}>
            <Routes>
            <Route path="/" element={<Navigate to="/v2/" />} />
              <Route path="/v2" children={<Layout />}>
                <Route path="" element={<AuthPage />} />
                <Route path="email-verification/:slug" element={<SuccessEmailVerificationPage />} />
                <Route path="invitation/:slug" element={<SuccessfulInvitationConfirmationPage />} />
                <Route path="password-update" element={<UpdatePasswordPage />} />
                <Route path="password-reset/:slug" element={<ResetPasswordPage />} />
                <Route path="email-verification" element={<EmailVerificationPage />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="onboarding" element={<OnboardingContainer />} />
                <Route path="enriched-data" element={<DataEnrichmentPage />} />
                <Route path="enriched-data-demo" element={<DataEnrichmentDemoPage />} />
                <Route path="api-keys" element={<SdkKeysPage />} />
                <Route path="company-profile" element={<CompanyProfilePage />} />
                <Route path="organization" element={<OrganizationProfilePage />} />
                <Route path="documentation" element={<DocumentationPage />} />
              </Route>
            </Routes>
          </Suspense>
        </Suspense>
      </NetworkConnectionHandler>
    </div>
    </QueryClientProvider>
  );
}

export default App;

const overlayDiv = {
  position: "absolute",
  top: "0",
  left: "3%",
  width: "100%",
  height: "100%",
  zIndex: "1",
};

const loadingText = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: "#fff",
  zIndex: "9999",
};
